<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb :title="city" :array="[
        { islink: true, url: '/', text: 'Ana Sayfa' },
        { islink: true, url: '/city', text: 'Şehirler' },
        { isCity: true },
      ]" :scity="true" />
    </section>
    <!--/Breadcrumb-->

    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-9 col-md-9" id="mobileEx">
            <!--Add lists-->
            <div class="mb-lg-0">
              <ProfileItem :list="list" :isLoad="isExpertLoad" :id="list.user_id" />
              <div class="center-block text-center mt-5">
                <ul class="pagination mb-5" v-if="isExpertLoad">
                  <li class="skeleton page-item page-prev w-8 p-4"></li>
                  <li v-for="(s, i) in 5" :key="i" style="margin-left: 5px" class="skeleton page-item w-6 p-4"></li>
                  <li style="margin-left: 5px" class="skeleton page-item page-next w-8 p-4"></li>
                </ul>
                <ul class="pagination mb-5" v-if="!isExpertLoad">
                  <pagination
                    :preload="'/city/' + this.$route.params.city + '/' + district + (this.$route.params.search != null ? '/' + this.$route.params.search : '') + '/page/'"
                    v-if="!isExpertLoad" :currentpage="currentpage" :pagesize="pagesize" />
                </ul>
              </div>
            </div>
            <!--Add lists-->
          </div>
          <div class="col-xl-3 col-lg-4 col-md-12">
            <Experience />
          </div>
        </div>
      </div>
    </section>
    <!--Add Listing-->
  </div>
</template>
<style scoped>
@media screen and (max-width: 990px) {
  #mobileEx {
    padding-left: 35px;
    padding-right: 35px;
  }
}
</style>
<script>
import ProfileItem from "./Components/ProfileItem.vue";
import Experience from "./Components/Experience.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import Pagination from "../../components/Pagination.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        { name: "robots", content: "noindex" },
        { name: "googlebot", content: "noindex" },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    if (this.$route.query.page != null) {
      this.currentpage = this.$route.query.page;
    }
    this.getList();
  },
  data() {
    return {
      title: "",
      description: "",
      info: [],
      list: [],
      filter: [],
      filtered: "",
      cityList: [],
      districtList: [],
      city: "",
      district: "",
      sdistrict: "",
      serviceList: [],
      currentpage: 1,
      perpage: 9,
      totalsize: 0,
      pagesize: 0,
      isExpertLoad: true,
    };
  },
  watch: {
    "$route.params.search": function () {
      this.getList();
    },
    "$route.params.page": function () {
      this.currentpage = this.$route.params.page;
      this.getList();
    },
  },
  methods: {
    filterItem(item) {
      let t = [];
      if (this.filtered != "") {
        t = this.filtered.split(",");
      }
      var found = t.indexOf(item);

      if (found < 0) {
        t.push(item);
      } else {
        t.splice(found, 1);
      }
      this.filtered = t.toString();
      this.getList();
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    locationChange() {
      let city = document.querySelector("select[name=city]").value;
      this.$router.push("/search/" + city + "/" + this.$route.params.search);
      this.getList();
    },
    disctrictChange() {
      let city = document.querySelector("select[name=city]").value;
      let district = document.querySelector("select[name=district]").value;
      this.$router.push(
        "/city/" + city + "/" + district + "/" + this.$route.params.search
      );
      window.location.reload();
      var experienceInfo = {
        location: city,
        province: district,
        search: this.$route.params.search,
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
      };
      experienceInfo.filtered = this.filtered;
      this.$store
        .dispatch("serviceUserFilter", experienceInfo)
        .then((value) => {
          this.info = value.info;
          this.filter = [];
          this.filter = value.filter;
          this.list = value.users;
          this.totalsize = value.size;
          this.pagesize = value.pages;
          this.isExpertLoad = false;
        });
    },
    getList() {
      let cityInfo = {};
      this.$store.dispatch("citysGet", cityInfo).then((value) => {
        this.cityList = value.list;
        this.city = this.cityList.filter(
          (country) => country.shortname == this.$route.params.city
        )[0].name;
        this.district = this.$route.params.districts;
        this.districtList = this.cityList.filter(
          (country) => country.shortname == this.$route.params.city
        )[0].districts;
        this.sdistrict = this.districtList.filter(
          (country) => country.shortname == this.district
        )[0].name;

        let experienceInfo;
        if (
          this.$route.params.search != "" &&
          this.$route.params.city != "" &&
          this.$route.params.districts != ""
        ) {
          experienceInfo = {
            search: this.$route.params.search,
            location: this.$route.params.city,
            province: this.$route.params.districts,
            begin: (this.currentpage - 1) * this.perpage,
            perpage: this.perpage,
          };
        } else if (
          this.$route.params.search != null &&
          !this.$route.params.location &&
          !this.$route.params.districts
        ) {
          experienceInfo = {
            search: this.$route.params.search,
            begin: (this.currentpage - 1) * this.perpage,
            perpage: this.perpage,
          };
        } else if (
          !this.$route.params.search &&
          !this.$route.params.location != null
        ) {
          experienceInfo = {
            location: this.$route.params.city,
            province: this.$route.params.districts,
            begin: (this.currentpage - 1) * this.perpage,
            perpage: this.perpage,
          };
        }
        experienceInfo.filtered = this.filtered;
        this.$store
          .dispatch("serviceUserFilter", experienceInfo)
          .then((value) => {
            this.info = value.info;

            this.list = value.users;
            this.filter = [];
            this.filter = value.filter;
            this.totalsize = value.size;
            this.pagesize = value.pages;
            this.isExpertLoad = false;

            this.title =
              this.city +
              " " +
              this.sdistrict +
              " " +
              (this.info.searchItem == "" || this.info.searchItem == null
                ? ""
                : this.info.searchItem) +
              " Uygulayan Doktorlar/Uzmanlar/Terapistler";

            this.districtList;
            this.description =
              this.city +
              " " +
              this.sdistrict +
              " " +
              (this.info.searchItem == "" || this.info.searchItem == null
                ? ""
                : this.info.searchItem) +
              " uygulayan Doktorlar/Uzmanlar/Terapistler listesidir. Yorumları inceleyebilir, Randevu alabilirsiniz.";
            let _this = this;
            $(document).ready(function () {
              let content = document.getElementById("appcontent").innerHTML;
              _this.$store.dispatch("setMetaContent", [
                _this.title,
                _this.description,
                "",
                "",
                "",
                content,
              ]);
            });
          });



      });

    },
  },
  components: { ProfileItem, Experience, Breadcrumb, Pagination },
  mounted() {
    this.backToTop();
    let serviceInfo = {};
    this.$store.dispatch("serviceList", serviceInfo).then((value) => {
      this.serviceList = value;
    });
  },
};
</script>